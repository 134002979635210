<template>
    <section class="my-0" :class="{'section': desktop}">
        <card class="border-0">
            <div class="row justify-content-md-center">
                <div class="col-lg-6">
                    <card type="secondary" shadow
                          header-classes="bg-white pb-5"
                          body-classes="px-lg-5 py-lg-5"
                          class="border-0">
                        <template>
                            <img v-lazy="'../img/svg/request.svg'" />
                            <p v-if="!$store.state.logged_in" class="lead" style="text-align: left; font-size: 17px; font-weight:400; line-height:normal !important">
                                {{$t('request.call')}}
                                <a href="javascript:void(0)" @click="$gtag.event('learn'); learn = true;">{{$t('global.learn')}}.</a>
                            </p>
                            <base-alert class="mt-4" v-if="alert.new" :type="alert.type">
                                <span class="alert-inner--text">{{alert.message}}</span>
                                <button type="button" class="close" data-dismiss="alert.new" aria-label="Close" @click="alert.new = false">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </base-alert>
                            <form class="mt-5" role="form">
                                <b-form-group>
                                    <b-form-radio-group v-model="model.type" button-variant="outline-primary" buttons :options="options">
                                    </b-form-radio-group>
                                </b-form-group>

                                <div class="mt-4 input-group-alternative">
                                    <label style="font-weight:bold;"  for="propertyType">{{$t('request.label.property')}}</label>
                                    <select v-model="model.property" id="propertyType" @change="getOptgroup($event)" class="propertySelection form-control">
                                        <optgroup :label="$t('request.sublabel.property.all.group')">
                                            <option value="any">{{$t('request.sublabel.property.all.any')}}</option>
                                        </optgroup>
                                        <optgroup :label="$t('request.sublabel.property.main.group')">
                                            <option value="apartment">{{$t('request.sublabel.property.main.apartment')}}</option>
                                            <option value="house">{{$t('request.sublabel.property.main.house')}}</option>
                                            <option value="office">{{$t('request.sublabel.property.main.office')}}</option>
                                            <option value="store">{{$t('request.sublabel.property.main.store')}}</option>
                                        </optgroup>
                                        <optgroup :label="$t('request.sublabel.property.residential.group')">
                                            <option value="apartment">{{$t('request.sublabel.property.residential.apartment')}}</option>
                                            <option value="condo">{{$t('request.sublabel.property.residential.condo')}}</option>
                                            <option value="house">{{$t('request.sublabel.property.residential.house')}}</option>
                                            <option value="penthouse">{{$t('request.sublabel.property.residential.penthouse')}}</option>
                                            <option value="kitchenette">{{$t('request.sublabel.property.residential.kitchenette')}}</option>
                                            <option value="loft">{{$t('request.sublabel.property.residential.loft')}}</option>
                                            <option value="studio">{{$t('request.sublabel.property.residential.studio')}}</option>
                                            <option value="farm">{{$t('request.sublabel.property.residential.farm')}}</option>
                                            <option value="ranch">{{$t('request.sublabel.property.residential.ranch')}}</option>
                                        </optgroup>
                                        <optgroup :label="$t('request.sublabel.property.commercial.group')">
                                            <option value="office">{{$t('request.sublabel.property.commercial.office')}}</option>
                                            <option value="shed">{{$t('request.sublabel.property.commercial.shed')}}</option>
                                            <option value="garage">{{$t('request.sublabel.property.commercial.garage')}}</option>
                                            <option value="warehouse">{{$t('request.sublabel.property.commercial.warehouse')}}</option>
                                            <option value="store">{{$t('request.sublabel.property.commercial.store')}}</option>
                                            <!--<option value="studio">{{$t('request.sublabel.property.commercial.studio')}}</option>-->
                                            <option v-if="model.type == 'buy'" value="land">{{$t('request.sublabel.property.residential.land')}}</option>
                                        </optgroup>
                                    </select>
                                </div>

                                <div class="mt-4 input-group-alternative">
                                    <label style="font-weight:bold;">{{$t('request.label.location')}}</label>
                                    <input autocomplete="no"
                                           :class="[{'form-control': true}, {'alert-border': alert.new}]"
                                           @blur="wipeOnBlur($event);"
                                           ref="mapcomplete"
                                           placeholder="" />
                                </div>

                                <!--<b-form-group v-if="model.type == 'rent'" class="mt-5">
                                    <b-form-radio-group class="justify-content-md-center" v-model="model.price_type">
                                        <b-form-radio value="rental"><label style="font-weight:bold;">{{$t('request.label.type.rental')}}</label></b-form-radio>
                                        <b-form-radio value="total"><label style="font-weight:bold;">{{$t('request.label.type.total')}}</label></b-form-radio>
                                    </b-form-radio-group>
                                </b-form-group>-->

                                <div class="mt-5" v-show="model.type == 'buy'">
                                    <vue-slider :tooltip-formatter="v => $t('request.tooltip.currency') + `${('' + v).replace(/\B(?=(\d{3})+(?!\d))/g, '.')}`"
                                                tooltip="always"
                                                v-model="buy_amount"
                                                :min="min_amount['buy']"
                                                :max="max_amount['buy']"
                                                :min-range="10000"
                                                :interval="1000">
                                    </vue-slider>
                                    <label style="font-weight:bold;">{{$t('request.label.price')}}</label>
                                </div>

                                <div class="mt-5" v-show="model.type == 'rent'">
                                    <vue-slider :tooltip-formatter="v => $t('request.tooltip.currency') + `${('' + v).replace(/\B(?=(\d{3})+(?!\d))/g, '.')}`"
                                                tooltip="always"
                                                v-model="rent_amount"
                                                :min="min_amount['rent']"
                                                :max="max_amount['rent']"
                                                :min-range="500"
                                                :interval="rent_amount[1] > 5000 ? 100 : 50">
                                    </vue-slider>
                                    <label style="font-weight:bold;">{{$t('request.label.price')}}</label>
                                </div>

                                <div class="mt-5">
                                    <vue-slider :tooltip-formatter="'{value} '+ $t('request.tooltip.meter')"
                                                tooltip="always"
                                                v-model="model.area"
                                                :min="min_area"
                                                :max="max_area"
                                                :min-range="5">
                                    </vue-slider>
                                    <label style="font-weight:bold;">{{$t('request.label.area')}}</label>
                                </div>

                                <div class="mt-5" v-if="model.type == 'rent'">
                                    <!--<div>
                                        <vue-slider :tooltip-formatter="'{value} ' + $tc('request.tooltip.month', model.rent_time)"
                                                    tooltip="always"
                                                    v-model="model.rent_time"
                                                    :marks="true"
                                                    :hide-label="true"
                                                    :min="1"
                                                    :max="12">
                                        </vue-slider>
                                        <label style="font-weight:bold;">{{$t('request.label.time')}}</label>
                                    </div>-->

                                    <base-checkbox class="mt-4" v-model="model.furnished" :disabled="disable()">
                                        <label style="font-weight:bold;">{{$t('request.label.furnished')}}</label>
                                    </base-checkbox>
                                </div>

                                <div class="mt-4 input-group-alternative">
                                    <label style="font-weight:bold;"  for="suite">{{$t('request.label.suite')}}</label>
                                    <select id="suite" v-model="model.suite" class="form-control" :disabled="disable()">
                                        <option value="0">{{$t('request.sublabel.generic.any')}}</option>
                                        <option value="1">1+</option>
                                        <option value="2">2+</option>
                                        <option value="3">3+</option>
                                    </select>
                                </div>

                                <div class="mt-4 input-group-alternative">
                                    <label style="font-weight:bold;"  for="room">{{$t('request.label.room')}}</label>
                                    <select id="room" v-model="model.room" class="form-control" :disabled="disable()">
                                        <option value="0">{{$t('request.sublabel.generic.any')}}</option>
                                        <option value="1">1+</option>
                                        <option value="2">2+</option>
                                        <option value="3">3+</option>
                                        <option value="4">4+</option>
                                    </select>
                                </div>

                                <div class="mt-4 input-group-alternative">
                                    <label style="font-weight:bold;" for="garage">{{$t('request.label.parking')}}</label>
                                    <select id="garage" v-model="model.garage" class="form-control" :disabled="disable()">
                                        <option value="0">{{$t('request.sublabel.generic.any')}}</option>
                                        <option value="1">1+</option>
                                        <option value="2">2+</option>
                                    </select>
                                </div>

                                <!--<div class="mt-4 input-group-alternative">
                                    <label style="font-weight:bold;"  for="floor">{{$t('request.label.floor')}}</label>
                                    <select id="floor" v-model="model.floor" form="request" class="form-control" :disabled="disable()">
                                        <option value="0">{{$t('request.sublabel.generic.any')}}</option>
                                        <option value="1">1</option>
                                        <option value="2">2</option>
                                        <option value="3">3+</option>
                                    </select>
                                </div>-->

                                <div class="mt-4 input-group-alternative">
                                    <label style="font-weight:bold;"  for="pet">{{$t('request.label.pet')}}</label>
                                    <select id="pet" v-model="model.pet" class="form-control" :disabled="disable('pet')">
                                        <option value="0">{{$t('request.sublabel.pet.whatever')}}</option>
                                        <option value="1">{{$t('request.sublabel.pet.yes')}}</option>
                                        <option value="2">{{$t('request.sublabel.pet.no')}}</option>
                                    </select>
                                </div>

                                <div class="mt-4 input-group-alternative">
                                    <label style="font-weight:bold;" >{{$t('request.label.feature')}}</label>
                                    <b-form-tags v-model="model.feature"
                                                 no-outer-focus
                                                 remove-on-delete
                                                 tag-pills
                                                 placeholder=""
                                                 v-b-popover.hover.bottomright="$t('request.label.tag')"
                                                 addButtonText="+"
                                                 addButtonVariant="primary"
                                                 addOnChange
                                                 separator=",;."
                                                 tagVariant="primary"
                                                 size="md"
                                                 duplicate-tag-text=""
                                                 id="feature">
                                    </b-form-tags>
                                </div>

                                <div class="mt-4 input-group-alternative">
                                    <label style="font-weight:bold;" >{{$t('request.label.detail')}}</label>
                                    <textarea class="form-control form-control-alternative" rows="2" v-model="model.detail"></textarea>
                                </div>

                                <!--<base-checkbox class="mt-4" v-model="model.static" v-b-popover.hover.top="$t('request.popover.static')">
            {{$t('request.label.static')}}
        </base-checkbox>-->

                                <base-button class="mt-4" type="primary" block @click="submitModel()">{{$t('request.submit')}}</base-button>
                            </form>
                        </template>
                    </card>
                </div>
            </div>
        </card>
        <modal :show.sync="register" style="text-align: justify; z-index: 9999999999999999999999" body-classes="p-0">
            <template slot="header">
                <h5 class="modal-title" style="text-align: left !important"><strong>{{$t('request.register')}}</strong></h5>
            </template>
            <!--<h3 slot="header" class="modal-title text-left" id="modal-title-default">{{$t('policy.title')}}</h3>-->
            <register-card source="modal"
                           type="requestor"
                           :showType="false"
                           @loggedIn="submitModel"
                           :login="true"></register-card>
            <!--<template slot="footer">
        <base-button type="link" class="ml-auto" @click="policy = false;">
            Fechar
        </base-button>
    </template>-->
        </modal>

        <modal :show.sync="learn" style="text-align: left; z-index: 9999999999999999999999" body-classes="p-0">
            <template slot="header">
                <h5 class="modal-title">{{$t('request.learn.title')}}</h5>
            </template>
            <section class="section bg-secondary">
                <div class="container">
                    <div>
                        <!--<div class="col-lg-6 text-center">
                    <img v-lazy="'../img/svg/landing/request.svg'" />
                </div>-->
                        <div class="row">
                            <div class="col">
                                <div class="row row-grid align-items-center px-3">
                                    <icon name="ni ni ni-square-pin" class="col-md-auto" size="md" type="white" color="primary" shadow rounded></icon>
                                    <h3 class="col">{{$t('landing.presentation.requestor.request.title')}}</h3>
                                </div>
                                <p class="lead">
                                    {{$t('landing.presentation.requestor.request.p1')}}
                                </p>
                                <p>
                                    {{$t('landing.presentation.requestor.request.p2')}}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section class="section">
                <div class="container">
                    <div>
                        <!--<div class="col-lg-6">
                    <img v-lazy="'../img/svg/landing/benefit.svg'" />
                </div>-->
                        <div class="row">
                            <div class="col">
                                <div class="row row-grid align-items-center px-3">
                                    <icon name="ni ni-active-40" class="col-md-auto" size="md" type="white" color="primary" shadow rounded></icon>
                                    <h3 class="col">{{$t('landing.presentation.requestor.benefit.title')}}</h3>
                                </div>
                                <p class="lead">
                                    {{$t('landing.presentation.requestor.benefit.p1')}}
                                </p>
                                <ul class="list-unstyled mt-5">
                                    <li class="py-2">
                                        <div class="d-flex align-items-center">
                                            <badge type="primary" class="mr-3" icon="ni ni-building ni-lg"></badge>
                                            <h6 class="mb-0">{{$t('landing.presentation.requestor.benefit.p2')}}</h6>
                                        </div>
                                    </li>
                                    <li class="py-2">
                                        <div class="d-flex align-items-center">
                                            <badge type="primary" class="mr-3" icon="ni ni-check-bold ni-lg"></badge>
                                            <h6 class="mb-0">{{$t('landing.presentation.requestor.benefit.p3')}}</h6>
                                        </div>
                                    </li>
                                    <li class="py-2">
                                        <div class="d-flex align-items-center">
                                            <badge type="primary" class="mr-3" icon="ni ni-chat-round ni-lg"></badge>
                                            <h6 class="mb-0">{{$t('landing.presentation.requestor.benefit.p4')}}</h6>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <template slot="footer">
                <base-button type="secondary" @click="learn = false">{{$t('global.close')}}</base-button>
            </template>
        </modal>

    </section>
</template>

<script>
    import VueSlider from 'vue-slider-component';
    import 'vue-slider-component/theme/antd.css';
    import gmapsInit from '../utils/gmaps';
    import "flatpickr/dist/flatpickr.css";
    import { BFormGroup, BFormRadioGroup } from 'bootstrap-vue';
    import $ from 'jquery';
    import RegisterCard from '../components/RegisterCard';

    export default {
        name: 'Request',
        components: {
            VueSlider,
            BFormGroup,
            BFormRadioGroup,
            RegisterCard
        },
        data() {
            return {
                document: {},

                model: {
                    type: 'rent',
                    property: 'any',
                    area: [15, 650],
                    price_type: 'total',
                    rent_time: 12,
                    furnished: false,
                    room: '0',
                    pet: '0',
                    floor: '0',
                    garage: '0',
                    suite: '0',
                    feature: [],
                    detail: '',
                    static: false,
                    currency: 'BRL',
                    property_group: this.$t('request.sublabel.property.all.group')
                },

                buy_amount: [0, 4000000],
                rent_amount: [0, 30000],
                min_amount: { rent: 0, buy: 0 },
                max_amount: { rent: 30000, buy: 4000000 },
                amount_step: { rent: 50, buy: 100 },
                min_area: 15,
                max_area: 650,
                selected: '',

                radios: [
                    { name: 'radio0', label: 'Rental' },
                    { name: 'radio1', label: 'Total' }
                ],

                filters: [/^[a-zA-Z\s]*$/],

                alert: {
                    message: '',
                    new: false,
                    type: 'warning'
                },
                submitTry: 1,
                desktop: screen.width > 700,
                register: false,
                learn: false,

                options: [
                    { text: this.$t('request.rent'), value: 'rent' },
                    { text: this.$t('request.buy'), value: 'buy' },
                ]
            }
        },
        watch: {            
            'model.type': function () {
                if (this.model.property == 'land' && this.model.type == 'rent') {
                    this.model.property = 'any';
                    this.model.property_group = this.$t('request.sublabel.property.all.group');
                }
            },
            deep: true,
        },
        methods: {
            enterFeature() {
                var e = $.Event("keypress", { which: 13 });
                $('#feature').trigger(e);                
            },
            updateModel(document) {
                this.document = document;
                this.model = { ...document, ...this.model };
            },
            updateInput(key, value, isModel) {
                if (typeof isModel === 'undefined') {
                    isModel = true;
                }

                if (isModel) {
                    this.model[key] = value;
                } else {
                    this.$data[key] = value;
                }
            },

            getInput(key) {
                return this.model[key]
            },
            wipeOnBlur(event) {
                event.target.value = '';
            },

            disable(tag = null) {
                if (tag == 'pet') {
                    return (['house', 'condo', 'land', 'ranch', 'farm'].includes(this.model.property) || [this.$t('request.sublabel.property.commercial.group')].includes(this.model.property_group));
                }
                if (tag == null) {
                    return (['land'].includes(this.model.property) || [this.$t('request.sublabel.property.commercial.group')].includes(this.model.property_group));
                }
            },
            pushAlert(message, type = null, from_server = null, route = null) {
                if (from_server) {
                    this.alert.message = this.$t(message);
                } else {
                    this.alert.message = message;
                }

                if (type == undefined) {
                    type = 'warning';
                }

                if (route) {
                    this.store.state.alert.message = message;
                    this.store.state.alert.new = true;
                    this.store.state.alert.type = type;
                    this.router.push(route);
                } else {
                    this.alert.new = true;
                    this.alert.type;
                    $("html, body").animate({ scrollTop: 0 }, 1000);
                }
            },

            submitModel() {
                var router = this.$router
                var gtag = this.$gtag;
                var submitTry = this.submitTry;
                var pushAlert = this.pushAlert;

                if (this.model.type == 'buy') {
                    this.model.amount = this.buy_amount;
                } else if (this.model.type == 'rent') {
                    this.model.amount = this.rent_amount;
                }                

                gtag.config('UA-158667515-1', {
                    'custom_map': {
                        'dimension1': 'try_number',
                        'dimension2': 'try_success',
                        'dimension3': 'user_type',
                    }
                });
                
                if (this.model.place === undefined) {
                    gtag.event('request_try', { 'try_number': submitTry, 'try_success': false })
                    submitTry++;
                    pushAlert(this.$t('warning.request.location'))
                } else {
                    if (this.$store.state.logged_in) {
                        this.register = false;
                        $.ajax({
                            url: 'https://api.immobl.com/request',
                            contentType: 'application/json;charset=UTF-8',
                            xhrFields: {
                                withCredentials: true
                            },
                            crossDomain: true,
                            type: 'POST',
                            data: JSON.stringify({ data: this.model }),
                            success: function (resp) {
                                if (resp.success) {
                                    gtag.event('request', {
                                        'event_callback': function () {
                                            router.push('/response/' + resp.request_id);
                                        }
                                    });
                                } else {
                                    gtag.event('reply_try', { 'try_number': submitTry, 'try_success': false })
                                    submitTry++;
                                    pushAlert(resp.message, 'warning', true, false);
                                }
                            },
                            //error: function (err) {
                            //    //console.log(err);
                            //}
                        });
                    } else {
                        this.register = true;
                        gtag.event('client_see_register_card');
                    }
                }
            },

            getOptgroup: function (event) {
                const index = event.target.selectedIndex;
                const option = event.target.options[index];
                const optgroup = option.parentElement;
                const label = optgroup.getAttribute('label');
                this.model.property_group = label;
            }
        },

        async mounted() {
            window.scrollTo(0, 0);
            var state = this.$store.state;
            const google = await gmapsInit();
            const input = this.$refs.mapcomplete;
            input.classList.add('autocompletePlaceholder');
            const autocomplete = new google.maps.places.Autocomplete(
                input, { types: ['geocode'], componentRestrictions: { country: this.$store.state.country.iso2 } });

            autocomplete.setFields(['address_component', 'geometry']);

            var updateInput = this.updateInput;

            autocomplete.addListener('place_changed', function () {
                const place = autocomplete.getPlace();

                if (!place.geometry) {
                    input.value = '';
                } else {
                    input.placeholder = input.value;
                    updateInput('location', input.value);
                    input.value = '';
                    //updateInput('alert', false, false);
                    updateInput('place', place.geometry.location.toString());
                    updateInput('address_components', place.address_components);
                }
            });

            if (state.logged_in) {
                this.$gtag.set({ 'user_id': this.$store.state._id });
            } else {
                if (state.early_session.request.available) {
                    var data = state.early_session.request.data;
                    this.updateModel(data);
                    input.placeholder = data.location;
                }
            }
        },
    }
</script>

<style scoped>
    .form {
        width: 50%;
        margin-left: 6px;
    }

    .alert-border {
        border-color: red;
    }

    .autocompletePlaceholder::-webkit-input-placeholder {
        color: #8898aa
    }
</style>